<template>
    <v-container fluid>
        <v-form @submit.prevent="getStatuses">
            <v-btn v-show="$vuetify.breakpoint.xsOnly" :to="{name: 'status.create'}" color="indigo" dark fab fixed top
                   right class="v-btn--add-form-top">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat light>
                    <v-card-text class="pb-0">
                        <v-row>
                            <v-col cols="12" sm="10">
                                <h3 class="headline pb-2" color="black">{{ $t('search_status') }}</h3>
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" sm="2" class="text-right">
                                <v-btn :to="{name: 'status.create'}" dark color="indigo">{{ $t('create') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12">
                                <ValidationProvider ref="status_name" name="status_name" rules="min:3|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="status_name" type="text" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-transfer"
                                                  :label="$t('status_name')"
                                                  color="primary" clearable></v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4">
                        <v-row>
                            <v-col cols="12" class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly"
                                       color="primary">{{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat light>
            <v-card-text class="px-0">
                <v-data-table :headers="headers" :items="statusItems" :loading="loading" :locale="lang"
                              :loading-text="$t('loading_please_wait')" class="elevation-1"
                              disable-pagination hide-default-footer>
                    <template v-slot:item.action="{ item }">

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="mr-2" @click="showStatusRailways(item)" :title="$t('status_railways')">
                                    mdi-menu
                                </v-icon>
                            </template>
                            <span>{{$t('status_railways')}}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="mr-2" @click="editStatus(item)" :title="$t('edit')">
                                    mdi-square-edit-outline
                                </v-icon>
                            </template>
                            <span>{{$t('edit')}}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click="deleteStatus(item)" :title="$t('delete')">
                                    mdi-delete-outline
                                </v-icon>
                            </template>
                            <span>{{$t('delete')}}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex";

    export default {
        name: "Statuses",
        components: {
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                status_name: null,
                sortBy: "name",
                sortDir: true,
                loading: false,
                statusItems: [],
                headers: [
                    {
                        text: this.$t('name'),
                        align: "left",
                        sortable: true,
                        value: "name"
                    },
                    {
                        text: this.$t('actions'),
                        align: "center",
                        value: 'action',
                        sortable: false,
                        width: 120,
                    },
                ],
            };
        },
        computed: {
            ...mapGetters(['lang'])
        },
        mounted() {
            this.getStatuses()
        },
        methods: {
            editStatus(item) {
                this.$router.push({
                    name: 'status.edit',
                    params: {
                        id: item.id
                    }
                });
            },
            showStatusRailways(item) {
                this.$router.push({
                    name: 'status_railway',
                    params: {
                        status_id: item.id
                    }
                });
            },

            async getStatuses() {
                this.loading = true;
                let params = {};
                if (this.status_name) {
                    params.status_name = this.status_name;
                }
                await this.$http
                    .get("admin/status", {
                        params: params,
                    })
                    .then(res => {
                        this.statusItems = res.body.data
                    })
                    .catch(err => {
                        this.statusItems = []
                        this.$toastr.error(this.$t('failed_to_get_list_statuses'));
                    })
                    .finally(end => {
                        this.loading = false
                    });
            },
            async deleteStatus(item) {
                const index = this.statusItems.indexOf(item)
                if (confirm(this.$t('delete_status') + ' ' + item.name)) {
                    this.loading = true;
                    await this.$http
                        .delete(`admin/status/${item.id}`)
                        .then(res => {
                            this.$toastr.success(this.$t('status_has_been_deleted'));
                            //this.statusItems.splice(index, 1)
                            //this.totalStatuses = (this.totalStatuses - 1)
                            this.getStatuses();
                        })
                        .catch(err => {
                            //this.statusItems = []
                            //this.totalStatuses = 0
                            this.$toastr.error(this.$t('status_has_not_been_deleted'));
                            if (err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        /*
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                         */
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message);
                                }
                            }
                        })
                        .finally(end => {
                            this.loading = false
                        });
                }
            },
        }
    };

</script>
